* {
  margin: 0;
  padding: 0;
  font-size: 10px;
  overflow-x: hidden;
}

.background, .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 1s ease-in-out;
}

.background {
  background-image: url("../assets/backgrounds/background2.jpeg");
  background-color: transparent; /* Fallback color */
  z-index: -2;
}

.overlay {
  background-image: url("../assets/backgrounds/background1.jpg"); /* Imagem inicial transparente */
  background-color: transparent; /* Fallback color */
  opacity: 0;
  z-index: -1;
}

.navbar {
  font-size: 3rem;
  width: 100vw;
  height: 75px;
  background-color: #f9ead4;
  position: fixed;
  margin-top: 0px !important;
  top: 0;
  font-family: "serenity", sans-serif;
  font-weight: 400;
  font-style: normal;
  align-items: center;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  transition: hover 0.5s;
}
.navbar .nav-logo {
  justify-self: start;
  margin-left: 2rem;
  cursor: pointer;
}
.navbar .nav-logo img {
  width: 140px;
}

.nav-btn {
  margin-right: 2rem;
  background: none;
  border: none;
  transition: 0.2s;
  transition: transform 0.5s;
  cursor: pointer;
  width: 30px;
}
.nav-btn:hover {
  transform: translateY(-0.1rem);
}
.nav-btn:active {
  transform: translateY(0.1rem);
}
.nav-btn:hover {
  transform: translateY(0);
}

.language-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.language-selector a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.8rem !important;
  font-family: "serenity", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.language-selector div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.3rem;
}

.disabled {
  display: none;
}

.dropdown {
  animation: dropdown 0.5s;
  width: 100vw;
  background-color: #E6CBAF;
  position: fixed;
  margin-top: 75px !important;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.dropdown ul li {
  margin: 2rem;
}
.dropdown ul a {
  font-family: "serenity", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2.5rem;
  color: #633808;
  text-decoration: none;
  margin: 2rem;
  cursor: pointer;
}
.dropdown .drop-lselect {
  display: flex;
  margin: 2rem;
  margin-left: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dropdown .drop-lselect a {
  font-family: "serenity", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2rem;
  margin: 0;
}
.dropdown .drop-lselect div {
  font-size: 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-weight: 100;
}

.home {
  height: 100vh;
}

.form {
  font-size: 4rem;
  width: 100vw;
  height: auto;
  background-color: #E6CBAF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  font-family: "serenity", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #633808;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  transition: font-size 0.5s;
}
.form .inputs-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* Cria 12 colunas */
}
.form .inputs-container .col-12 {
  grid-column: span 12; /* Ocupa todas as 12 colunas */
  margin: 0.6rem; /* Centraliza o container */
  display: flex;
  justify-content: center;
  align-items: center;
}
.form .inputs-container .col-6 {
  grid-column: span 6; /* Ocupa 6 colunas */
  margin: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form label {
  font-size: 1.5rem;
  margin-right: 10px;
  justify-content: center;
  overflow: visible;
}
.form form {
  margin: 10px;
}
.form form input {
  font-size: 1.5rem;
  padding: 5px;
  background-color: #F9EAD4;
  color: #633808;
  border: none;
  font-family: "SF Pro", "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.form form input:focus {
  outline: none;
}
.form form button {
  font-size: 1.5rem;
  background: none;
  border: 2px solid;
  color: #633808;
  font-family: "serenity", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 1rem 2rem;
  transition: transform 0.5s;
  cursor: pointer;
  transition: all 0.5s;
}
.form form button:hover {
  transform: translateY(-0.1rem);
}
.form form button:active {
  transform: translateY(0.1rem);
}
.form form button img {
  width: 1.5rem;
}
.form form button:hover {
  transform: translateY(0);
  background-color: #F9EAD4;
}
.form form button:active {
  transform: translateY(0);
  background-color: #f8e6cb;
}

.botao-online {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.o-espaco {
  font-size: 4rem;
  width: 100vw;
  height: auto;
  background-color: #F9EAD4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  color: #633808;
}
.o-espaco .titulo {
  font-family: "serenity", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 4rem;
}
.o-espaco img {
  width: 340px;
  height: 203px;
  display: flex;
  align-items: center;
  justify-content: center;
  -o-object-fit: cover;
     object-fit: cover;
  margin-bottom: 1rem;
}
.o-espaco div {
  width: 340px;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: "SF Pro", "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 1rem;
}
.o-espaco div p {
  margin: 0.5rem;
  font-size: 1.8rem;
  text-align: center;
  overflow: visible;
}
.o-espaco .agra-lemos {
  color: #633808;
  transition: all 0.5s;
  font-size: 1.8rem;
}
.o-espaco .agra-lemos:hover {
  color: #92530c;
}

.diferenciais {
  font-size: 4rem;
  width: 100vw;
  height: auto;
  background-color: #E6CBAF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #633808;
}
.diferenciais .titulo {
  font-family: "serenity", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 4rem;
  justify-content: center;
  margin-bottom: 1rem;
}
.diferenciais .subtitulo {
  font-weight: 100;
  font-size: 2rem;
  overflow: visible;
  text-align: center;
}
.diferenciais .diferencial {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "SF Pro", "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 2rem;
}
.diferenciais .diferencial img {
  width: 20rem;
  height: 20rem;
  -o-object-fit: contain;
     object-fit: contain;
  margin-bottom: 1.5rem;
}
.diferenciais .diferencial p {
  font-size: 1.7rem;
  overflow: visible;
  width: 30rem;
  text-align: center;
}
.diferenciais .diferencial button {
  text-decoration: none;
  background: none;
  color: #633808;
  font-family: "SF Pro", "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  border: solid 2px #633808;
  font-size: 1.3rem;
  padding: 0.65rem 1.3rem;
  margin: 1rem;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  transition: all 0.5s;
}
.diferenciais .diferencial button:hover {
  transform: translateY(-1px);
  background-color: #F9EAD4;
}
.diferenciais .diferencial button:active {
  transform: translateY(1px);
  background-color: #f8e6cb;
}

.massagueira {
  font-size: 4rem;
  width: 100vw;
  height: auto;
  background-color: #DCB48B;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #633808;
}
.massagueira .titulo {
  font-family: "serenity", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 4rem;
  justify-content: center;
  margin-bottom: 1rem;
}
.massagueira .grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.massagueira .grid iframe {
  width: 30rem;
  height: auto;
  margin-bottom: 1.5rem;
}
.massagueira .grid p {
  font-family: "SF Pro", "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.7rem;
  overflow: visible;
  width: 30rem;
  text-align: center;
}

.contato {
  font-size: 4rem;
  width: 100vw;
  height: auto;
  background-color: #633808;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #F9EAD4;
}
.contato .titulo {
  font-family: "serenity", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-decoration: underline;
  font-size: 3rem;
  margin-bottom: 1rem;
}
.contato .dados {
  font-family: "SF Pro", "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
  width: 74%;
}
.contato .dados img {
  width: 16px;
  height: 16px;
}
.contato .dados div {
  font-size: 2rem;
}
.contato .dados b {
  font-size: 2rem;
}
.contato .dados a {
  font-size: 2rem;
  text-decoration: none;
  color: #F9EAD4;
  cursor: pointer;
}
.contato .dados .reservas {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.contato .dados .localizacao {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.contato .social {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.contato .social img {
  height: 40px;
  width: 40px;
  margin: 0.5rem;
  text-decoration: none;
  color: #F9EAD4;
}
.contato .creditos {
  font-family: "SF Pro", sans-serif;
}
.contato .creditos a {
  text-decoration: none;
  color: #F9EAD4;
}

.modal {
  display: flex;
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #F9EAD4;
  background-color: rgba(99, 56, 8, 0.5);
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #F9EAD4;
  width: 370px;
  height: 350px;
}

.slideshow-container {
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
}

.mySlides {
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.mySlides .mySlides-img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}

.prev, .next {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover, .next:hover {
  background-color: rgba(99, 56, 8, 0.8);
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@media only screen and (min-width: 900px) {
  * {
    margin: 0;
    padding: 0;
    font-size: 20px;
    overflow-x: hidden;
  }
  .navbar {
    width: 100vw;
    height: 75px;
    background-color: #f9ead4;
    position: fixed;
    margin-top: 0px !important;
    top: 0;
    font-family: "serenity", sans-serif;
    font-weight: 400;
    font-style: normal;
    align-items: center;
    display: flex;
    justify-content: space-between;
    z-index: 1;
  }
  .nav-logo {
    justify-self: start;
    margin-left: 2rem;
    cursor: pointer;
  }
  .nav-logo img {
    width: 140px;
  }
  .nav-options {
    margin-right: 2rem;
    list-style: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav-options li {
    margin-left: 1.2vw;
  }
  .nav-options a {
    text-decoration: none;
    color: #633808;
    transition: hover 0.5s;
    font-size: 1rem;
    cursor: pointer;
  }
  .nav-options .scrollLink {
    text-decoration: none;
    color: #633808;
    transition: hover 0.5s;
    font-size: 1rem;
    cursor: pointer;
  }
  .nav-options a:hover {
    color: #92530c;
  }
  .dropdown {
    display: none;
  }
  .form {
    font-size: 4rem;
    width: 100vw;
    height: auto;
    background-color: #E6CBAF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    font-family: "serenity", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #633808;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }
  .form label {
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
  .form .inputs-container {
    display: flex;
  }
  .form .inputs-container .col-6 {
    display: flex;
  }
  .form .inputs-container .col-12 {
    display: flex;
  }
  .form .inputs-container .col-12 input {
    width: 131.833px;
  }
  .form form {
    margin: 10px;
  }
  .form form input {
    font-size: 0.8rem;
    padding: 8px 16px;
    background-color: #F9EAD4;
    color: #633808;
    border: none;
    font-family: "SF Pro", "Inter", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .form form button {
    font-size: 0.8rem;
    background: none;
    color: #633808;
    font-family: "serenity", sans-serif;
    font-weight: 400;
    font-style: normal;
    border: 2px solid;
    padding: 0.3rem 0.6rem;
    transition: transform 0.5s;
    text-align: center;
    display: inline-block;
    transition: all 0.5s;
  }
  .form form button:hover {
    transform: translateY(-0.1rem);
  }
  .form form button:active {
    transform: translateY(0.1rem);
  }
  .form form button img {
    width: 0.8rem;
  }
  .form form button:hover {
    transform: translateY(0);
  }
  .o-espaco {
    font-size: 4rem;
    width: 100vw;
    height: auto;
    background-color: #F9EAD4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  .o-espaco .titulo {
    font-size: 2.5rem;
    justify-content: center;
  }
  .o-espaco div {
    justify-content: center;
    width: 80%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }
  .o-espaco div img {
    width: 680px;
    height: 406px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .o-espaco div p {
    width: 680px;
    font-size: 1rem;
  }
  .o-espaco .o-espaco-1 {
    margin-bottom: 0;
  }
  .o-espaco .o-espaco-2 {
    margin-top: 0;
    flex-direction: row-reverse;
  }
  .o-espaco .agra-lemos {
    font-size: 1rem;
  }
  .diferenciais {
    font-size: 4rem;
    width: 100vw;
    height: auto;
    background-color: #E6CBAF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 2rem;
    display: flex;
    align-items: center;
    color: #633808;
  }
  .diferenciais .grid {
    display: flex;
    flex-direction: row;
  }
  .diferenciais .titulo {
    font-family: "serenity", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.5rem;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .diferenciais .subtitulo {
    font-weight: 100;
    font-size: 1.2rem;
    overflow: visible;
    text-align: center;
  }
  .diferenciais .diferencial {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "SF Pro", "Inter", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: 2vw;
  }
  .diferenciais .diferencial img {
    width: 10rem;
    height: 10rem;
    -o-object-fit: contain;
       object-fit: contain;
    margin-bottom: 1.5rem;
  }
  .diferenciais .diferencial p {
    font-size: 1rem;
    overflow: visible;
    width: 10rem;
    text-align: center;
  }
  .diferenciais .diferencial button {
    text-decoration: none;
    background: none;
    color: #633808;
    font-family: "SF Pro", "Inter", sans-serif;
    font-weight: 400;
    font-style: normal;
    border: 2px solid;
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
    text-align: center;
    display: inline-block;
  }
  .massagueira {
    font-size: 4rem;
    width: 100vw;
    height: auto;
    background-color: #DCB48B;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #633808;
  }
  .massagueira .titulo {
    font-family: "serenity", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.5rem;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .massagueira .grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .massagueira .grid iframe {
    width: 22rem;
    height: 15rem;
    margin: 0.75rem;
  }
  .massagueira .grid p {
    font-family: "SF Pro", "Inter", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    overflow: visible;
    width: 22rem;
    text-align: center;
    margin: 0.75rem;
  }
  .contato {
    font-size: 4rem;
    width: 100vw;
    height: auto;
    background-color: #633808;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #F9EAD4;
  }
  .contato .titulo {
    font-family: "serenity", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-decoration: underline;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  .contato .dados {
    font-family: "SF Pro", "Inter", sans-serif;
    font-weight: 400;
    font-style: normal;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    text-align: start;
    width: 75%;
  }
  .contato .dados img {
    width: 16px;
    height: 16px;
  }
  .contato .dados div {
    font-size: 0.9rem;
  }
  .contato .dados b {
    font-size: 1rem;
  }
  .contato .dados a {
    font-size: 1rem;
    text-decoration: none;
    color: #F9EAD4;
  }
  .contato .dados .reservas {
    margin: 0;
    overflow: visible;
    margin-right: 1rem;
  }
  .contato .dados .localizacao {
    margin: 0;
    margin-left: 1rem;
  }
  .contato .social {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .contato .social img {
    height: 40px;
    width: 40px;
    margin: 0.5rem;
    text-decoration: none;
    color: #F9EAD4;
  }
  .contato .creditos {
    font-family: "SF Pro", sans-serif;
  }
  .contato .creditos a {
    text-decoration: none;
    color: #F9EAD4;
    font-size: 0.8rem;
  }
  .modal {
    display: none;
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #F9EAD4;
    background-color: rgba(99, 56, 8, 0.5);
  }
  .modal-content {
    background-color: #F9EAD4;
    width: 720px; /* Could be more or less, depending on screen size */
    height: 480px;
  }
  .slideshow-container {
    width: 720px;
    height: 480px;
    position: relative;
    margin: auto;
  }
  .mySlides {
    display: none;
    width: 720px;
    height: 480px;
  }
  .mySlides .mySlides-img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  .prev:hover, .next:hover {
    background-color: rgba(99, 56, 8, 0.8);
  }
  .fade {
    animation-name: fade;
    animation-duration: 1.5s;
  }
}
@keyframes dropdown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes fade {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}/*# sourceMappingURL=style.css.map */