$cor-de-fundo: #F9EAD4;
$fonte: #633808;

@mixin fonte-logo {
    font-family: "serenity", sans-serif;
    font-weight: 300;
    font-style: normal;
}

@mixin fonte-secundaria {
    font-family: "SF Pro", "Inter", sans-serif;
    font-weight: 400;
    font-style: normal;
}

@mixin botao {
    transition: transform 0.5s;
    &:hover {
        transform: translateY(-.1rem);
    }

    &:active {
        transform: translateY(.1rem);
    }
}

@mixin divisao ($cor) {
    font-size: 4rem;
    width: 100vw;
    height: auto;
    background-color: $cor;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

* {
    margin: 0;
    padding: 0;
    font-size: 10px;
    overflow-x: hidden;
}

.background, .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: opacity 1s ease-in-out;
}

.background {
    background-image: url('../assets/backgrounds/background2.jpeg');
    background-color: transparent; /* Fallback color */
    z-index: -2;
}

.overlay {
    background-image: url('../assets/backgrounds/background1.jpg'); /* Imagem inicial transparente */
    background-color: transparent; /* Fallback color */
    opacity: 0;
    z-index: -1;
}

.navbar {
    font-size: 3rem;
    width: 100vw;
    height: 75px;
    background-color: rgba($cor-de-fundo, 1);
    position: fixed;
    margin-top: 0px !important;
    top: 0;
    @include fonte-logo();
    align-items: center;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    transition: hover .5s;

    .nav-logo {
        justify-self: start;
        margin-left: 2rem;
        img {
            width: 140px;
        }
        cursor: pointer;
    }
}

.nav-btn {
    margin-right: 2rem;
    background: none;
    border: none;
    transition: 0.2s;
    @include botao();
    cursor: pointer;
    width: 30px;
    &:hover {
        transform: translateY(0);
    }
}

.language-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 0.8rem !important;
        @include fonte-logo();
    }
    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 0.3rem;
    }
}

.disabled {
    display: none;
}

.dropdown {
    animation: dropdown .5s;
    width: 100vw;
    background-color: #E6CBAF;
    position: fixed;
    margin-top: 75px !important;
    top: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ul {
        li {
            margin: 2rem;
        }
        a {
            @include fonte-logo();
            font-size: 2.5rem;
            color: $fonte;
            text-decoration: none;
            margin: 2rem;
            cursor: pointer;
        }
    }
    .drop-lselect {
        display: flex;
        margin: 2rem;
        margin-left: 4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        a {
            @include fonte-logo();
            font-size: 2rem;
            margin: 0;
        }
        div {
            font-size: 2rem;
            margin-left: .5rem;
            margin-right: .5rem;
            font-weight: 100;
        }
    }
}

.home {
    height: 100vh;
}

.form {
    @include divisao(#E6CBAF);
    @include fonte-logo();
    color: $fonte;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    transition: font-size 0.5s;
    .inputs-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(12, 1fr); /* Cria 12 colunas */
        .col-12 {
            grid-column: span 12; /* Ocupa todas as 12 colunas */
            margin: .6rem; /* Centraliza o container */
            display: flex;
            justify-content: center;
            align-items: center;
        }
    
        .col-6 {
            grid-column: span 6; /* Ocupa 6 colunas */
            margin: .6rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    label {
        font-size: 1.5rem;
        margin-right: 10px;
        justify-content: center;
        overflow: visible;
    }
    form {
        margin: 10px;
        input {
            font-size: 1.5rem;
            padding: 5px;
            // border-radius: 15px;
            background-color: $cor-de-fundo;
            color: $fonte;
            border: none;
            @include fonte-secundaria();
            &:focus {
                outline: none;
            }
        }
        
        button {
            font-size: 1.5rem;
            // border-radius: 15px;
            background: none;
            border: 2px solid;
            color: $fonte;
            @include fonte-logo();
            padding: 1rem 2rem;
            @include botao();
            cursor: pointer;
            transition: all .5s;
            img {
                width: 1.5rem;
            }
            &:hover {
                transform: translateY(0);
                background-color: $cor-de-fundo;
            }
            &:active {
                transform: translateY(0);
                background-color: darken($cor-de-fundo, 2%);
            }
        }
    }
}

.botao-online {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.o-espaco {
    @include divisao($cor-de-fundo);
    display: flex;
    flex-direction: column;
    color: $fonte;
    .titulo {
        @include fonte-logo();
        font-size: 4rem;
    }
    img {
        // border-radius: 10px;
        width: 340px;
        height: 203px;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        margin-bottom: 1rem;
    }
    div {
        width: 340px;
        align-items: center;
        display: flex;
        flex-direction: column;
        @include fonte-secundaria();
        margin: 1rem;
        p {
            margin: 0.5rem;
            font-size: 1.8rem;
            text-align: center;
            overflow: visible;
        }
    }
    .agra-lemos {
        color: $fonte;
        transition: all 0.5s;
        font-size: 1.8rem;
        &:hover {
            color: lighten($fonte, 10%);
        }
    }
}

.diferenciais {
    @include divisao(#E6CBAF);
    font-size: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: $fonte;
    .titulo {
        @include fonte-logo();
        font-size: 4rem;
        justify-content: center;
        margin-bottom: 1rem;
    }
    .subtitulo {
        font-weight: 100;
        font-size: 2rem;
        overflow: visible;
        text-align: center;
    }
    .diferencial {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include fonte-secundaria();
        margin-bottom: 2rem;
        img {
            width: 20rem;
            height: 20rem;
            object-fit: contain;
            margin-bottom: 1.5rem;
        }
        p {
            font-size: 1.7rem;
            overflow: visible;
            width: 30rem;
            text-align: center
        }
        button {
            text-decoration: none;
            background: none;
            color: $fonte;
            @include fonte-secundaria();
            // border-radius: 15px;
            border: solid 2px $fonte;
            font-size: 1.3rem;
            padding: .65rem 1.3rem;
            margin: 1rem;
            cursor: pointer;
            text-align: center;
            display: inline-block;
            transition: all 0.5s;
        }
        button:hover {
            transform: translateY(-1px);
            background-color: $cor-de-fundo;
        }
        button:active {
            transform: translateY(1px);
            background-color: darken($cor-de-fundo, 2%);
        }
    }
}

.massagueira {
    @include divisao(#DCB48B);
    font-size: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: $fonte;
    .titulo {
        @include fonte-logo();
        font-size: 4rem;
        justify-content: center;
        margin-bottom: 1rem;
    }
    .grid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        iframe {
            width: 30rem;
            height: auto;
            margin-bottom: 1.5rem;
            // border-radius: 10px;
        }
        p {
            @include fonte-secundaria();
            font-size: 1.7rem;
            overflow: visible;
            width: 30rem;
            text-align: center
        }
    }
}   

.contato {
    @include divisao($fonte);
    font-size: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: $cor-de-fundo;
    .titulo {
        @include fonte-logo();
        text-decoration: underline;
        font-size: 3rem;
        margin-bottom: 1rem;
    }
    .dados {
        @include fonte-secundaria();
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: start;
        width: 74%;
        img {
            width: 16px;
            height: 16px;
        }
        div {
            font-size: 2rem;
        }
        b {
            font-size: 2rem;
        }
        a {
            font-size: 2rem;
            text-decoration: none;
            color: $cor-de-fundo;
            cursor: pointer;
        }
        .reservas {
            margin-top: 1rem;
            margin-bottom: .5rem;
        }
        .localizacao {
            margin-top: .5rem;
            margin-bottom: 1rem;
        }
    }
    .social {
        margin-top: 1rem;
        margin-bottom: 2rem;
        img {
            height: 40px;
            width: 40px;
            margin: .5rem;
            text-decoration: none;
            color: $cor-de-fundo;
        }
    }
    .creditos {
        font-family: 'SF Pro', sans-serif;
        a {
            text-decoration: none;
            color: $cor-de-fundo;
        }
    }
}

.modal {
    display: flex;
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: $cor-de-fundo;
    background-color: rgba($fonte, 50%);
    align-items: center;
    justify-content: center;
}

.modal-content {
    background-color: $cor-de-fundo;
    // border-radius: 15px;
    width: 370px;
    height: 350px;
}
  
.slideshow-container {
    width: 100%;
    height: 100%;
    position: relative;
    margin: auto;
}
  
.mySlides {
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .mySlides-img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
}
  
.prev, .next {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}
  
.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}
  
.prev:hover, .next:hover {
    background-color: rgba($fonte ,0.8);
} 
  
.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@media only screen and (min-width: 900px) {
    * {
        margin: 0;
        padding: 0;
        font-size: 20px;
        overflow-x: hidden;
    }    

    .navbar {
        width: 100vw;
        height: 75px;
        background-color: rgba($cor-de-fundo, 1); 
        position: fixed;
        margin-top: 0px !important;
        top: 0;
        @include fonte-logo();
        align-items: center;
        display: flex;
        justify-content: space-between;
        z-index: 1;
    }
    
    .nav-logo {
        justify-self: start;
        margin-left: 2rem;
        cursor: pointer;
        img {
            width: 140px;
        }
    }
    
    .nav-options {
        margin-right: 2rem;
        list-style: none;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            margin-left: 1.2vw;
        }
        a {
            text-decoration: none;
            color: $fonte;
            transition: hover 0.5s;
            font-size: 1rem;
            cursor: pointer;
        }
        .scrollLink {
            text-decoration: none;
            color: $fonte;
            transition: hover 0.5s;
            font-size: 1rem;
            cursor: pointer;
        }
        a:hover {
            color: lighten($fonte, 10%);
        }
    }

    .dropdown {
        display: none;
    }

    .form {
        @include divisao(#E6CBAF);
        @include fonte-logo();
        color: $fonte;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        label {
            font-size: 1rem;
            display: flex;
            align-items: center;
        }
        .inputs-container {
            display: flex;
            .col-6 {
                display: flex;
            }
            .col-12 {
                display: flex;
                input {
                    width: 131.833px;
                }
            }
        }
        form {
            margin: 10px;
            input {
                font-size: 0.8rem;
                padding: 8px 16px;
                // border-radius: 15px;
                background-color: $cor-de-fundo;
                color: $fonte;
                border: none;
                @include fonte-secundaria();
            }
            
            button {
                font-size: 0.8rem;
                // border-radius: 15px;
                background: none;
                color: $fonte;
                @include fonte-logo();
                border: 2px solid;
                padding: .3rem 0.6rem;
                @include botao();
                text-align: center;
                display: inline-block;
                transition: all .5s;
                img {
                    width: 0.8rem;
                }
                &:hover {
                    transform: translateY(0);
                }
            }
        }
    }

    .o-espaco {
        @include divisao($cor-de-fundo);
        font-size: 2rem;
        display: flex;
        align-items: center;
        .titulo {
            font-size: 2.5rem;
            justify-content: center;
        }
        div {
            justify-content: center;
            width: 80%;
            display: flex;
            flex-direction: row;
            img {
                // border-radius: 10px;
                width: 680px;
                height: 406px;
                object-fit: cover;
            }
            p {
                width: 680px;
                font-size: 1rem;
            }
            overflow: hidden;
        }
        .o-espaco-1 {
            margin-bottom: 0;
        }
        .o-espaco-2 {
            margin-top: 0;
            flex-direction: row-reverse;
        }
        .agra-lemos {
            font-size: 1rem;
        }
    }
    
    .diferenciais {
        @include divisao(#E6CBAF);
        font-size: 2rem;
        display: flex;
        align-items: center;
        color: $fonte;
        .grid {
            display: flex;
            flex-direction: row;
        }
        .titulo {
            @include fonte-logo();
            font-size: 2.5rem;
            justify-content: center;
            margin-bottom: 1rem;
        }
        .subtitulo {
            font-weight: 100;
            font-size: 1.2rem;
            overflow: visible;
            text-align: center;
        }
        .diferencial {
            display: flex;
            flex-direction: column;
            align-items: center;
            @include fonte-secundaria();
            margin: 2vw;
            img {
                width: 10rem;
                height: 10rem;
                object-fit: contain;
                margin-bottom: 1.5rem;
            }
            p {
                font-size: 1rem;
                overflow: visible;
                width: 10rem;
                text-align: center;
            }
            button {
                text-decoration: none;
                background: none;
                color: $fonte;
                @include fonte-secundaria();
                // border-radius: 15px;
                border: 2px solid;
                font-size: 0.8rem;
                padding: .4rem .8rem;
                text-align: center;
                display: inline-block;
            }
        }
    }

    .massagueira {
        @include divisao(#DCB48B);
        font-size: 2rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: $fonte;
        .titulo {
            @include fonte-logo();
            font-size: 2.5rem;
            justify-content: center;
            margin-bottom: 1rem;
        }
        .grid {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            iframe {
                width: 22rem;
                height: 15rem;
                // border-radius: 10px;
                margin: 0.75rem;
            }
            p {
                @include fonte-secundaria();
                font-size: 1rem;
                overflow: visible;
                width: 22rem;
                text-align: center;
                margin: 0.75rem;
            }
        }
    }   
    
    .contato {
        @include divisao($fonte);
        font-size: 2rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: $cor-de-fundo;
        .titulo {
            @include fonte-logo();
            text-decoration: underline;
            font-size: 2.5rem;
            margin-bottom: 1rem;
        }
        .dados {
            @include fonte-secundaria();
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: center;
            text-align: start;
            width: 75%;
            img {
                width: 16px;
                height: 16px;
            }
            div {
                font-size: .9rem;
            }
            b {
                font-size: 1rem;
            }
            a {
                font-size: 1rem;
                text-decoration: none;
                color: $cor-de-fundo;
            }
            .reservas {
                margin: 0;
                overflow: visible;
                margin-right: 1rem;
            }
            .localizacao {
                margin: 0;
                margin-left: 1rem;
            }
        }
        .social {
            margin-top: 1rem;
            margin-bottom: 1rem;
            img {
                height: 40px;
                width: 40px;
                margin: .5rem;
                text-decoration: none;
                color: $cor-de-fundo;
            }
        }
        .creditos {
            font-family: 'SF Pro', sans-serif;
            a {
                text-decoration: none;
                color: $cor-de-fundo;
                font-size: .8rem;
            }
        }
    }

    .modal {
        display: none;
        position: fixed;
        z-index: 3;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        background-color: $cor-de-fundo;
        background-color: rgba($fonte, 50%);
    }
    
    .modal-content {
        background-color: $cor-de-fundo;
        // border-radius: 15px;
        width: 720px; /* Could be more or less, depending on screen size */
        height: 480px;
    }
      
    .slideshow-container {
        width: 720px;
        height: 480px;
        position: relative;
        margin: auto;
    }
      
    .mySlides {
        display: none;
        width: 720px;
        height: 480px;
        .mySlides-img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            overflow: hidden;
          }
    }
      
    .prev, .next {
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: auto;
        margin-top: -22px;
        padding: 16px;
        color: white;
        font-weight: bold;
        font-size: 18px;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
        user-select: none;
    }
      
    .next {
        right: 0;
        border-radius: 3px 0 0 3px;
    }
      
    .prev:hover, .next:hover {
        background-color: rgba($fonte ,0.8);
    }
    
    .fade {
        animation-name: fade;
        animation-duration: 1.5s;
    }
}

@keyframes dropdown {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes fade {
    from {opacity: .8}
    to {opacity: 1}
}